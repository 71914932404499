body {
  margin: 0;
  font-family: "Patrick Hand SC", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1e8d7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wrapper {
  max-width: 1200px;
  width: 100%;
  margin: 20px auto;
}

.map-container {
  min-height: 70vh;
  max-height: 1000px;
}

h1 {
  text-align: center;
  font-size: 60px;
  line-height: 80px;
  letter-spacing: 1px;
}

.legend {
  text-align: center;
}
.credits {
  margin: 20px;
  text-align: center;
}
.credits a {
  color: black;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 40px;
    line-height: 45px;
    letter-spacing: normal;
  }
}
